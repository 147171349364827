<template>
  <v-app>
    <v-app-bar app dark>
      <v-app-bar-title>natuneko.cc</v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn
        icon
        class="mx-1"
        :ripple="false"
        href="https://github.com/natuneko/"
        target="_blank"
      >
        <v-icon large>mdi-github</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container>
        <h1 class="text-center my-10">Welcome to natuneko.cc</h1>

        <v-simple-table>
          <tbody>
            <tr>
              <td>名前</td>
              <td>夏猫</td>
            </tr>

            <tr>
              <td>使ってる言語</td>
              <td>Go、Python、Vue.js</td>
            </tr>

            <tr>
              <td>趣味</td>
              <td>音楽（ピアノ練習中）、プログラミング</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-container>
    </v-main>
    <v-footer app dark>
      <v-col class="text-center">
        ©{{ new Date().getFullYear() }} natuneko
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
export default {};
</script>
